<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" day-date="true" customer="true" />

    <v-row>
      <v-col>
        <span>
          F+3/12<br />
          10/26/2020 11/17/2020<br />
          Day 1 of 12<br />
          Evaluator = Billy Krohne 816-123-1234
        </span>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <v-col>
        <span class="mr-5">PIR</span><span>Piers - 11 Ext</span><br />
        <span class="mr-5">CRR</span><span>Crack Repair - 32 ft NP1</span>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'Drive2Job' })"
        >
          Drive 2 Job
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayBidItems' })"
          large
          class="custom-btn-block"
          >Bid Items
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayMaterials' })"
          large
          class="custom-btn-block"
        >
          Materials
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayChecklist' })"
          large
          class="custom-btn-block"
          >Checklist
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayFeedback' })"
          large
          class="custom-btn-block"
        >
          Feedback
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayPhotos' })"
          large
          class="custom-btn-block"
          >Photos
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayDocuments' })"
          large
          class="custom-btn-block"
        >
          Documents
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayCloseOut' })"
          large
          class="custom-btn-block"
          >Closeout
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayReassign' })"
          large
          class="custom-btn-block"
        >
          Reassign
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayProjectMain",
  components: { CustomerInfo },
  data() {
    return {};
  },
};
</script>

<style scoped>
.col-height {
  height: 115px;
}

.custom-btn-block {
  height: 100% !important;
  width: 100% !important;
}
</style>
